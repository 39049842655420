@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: black !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-conf{
  z-index: 1;
}

.home-head{
  background-image: url("../public/assets/head.jpg");

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

.aboutfavela-background {
  position: relative;
  width:100%;
}

.aboutfavela-background:after {
  content:'';
  background: url("../public/assets/head.jpg") no-repeat center center;
  position: absolute;
  top:0px;
  left: 0px;
  width:100%;
  height:100%;
  z-index:-1;
  opacity: 0.3;
  filter: grayscale(100%); 
  background-attachment: fixed;
}


.sidebar{
  min-height: 100vh;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 1;
  transition: 0.5s;
}
.sidebar.active{
  right: 0;
  background-color: #080808;
}

.program { 
  overflow: hidden;
  transition: max-height 500ms;
  transition-timing-function: ease-in-out;
  max-height: 0;
}

.program.active{
  max-height: 3000px;
}

.contact{
  display: none;
  width: 100%;
}
.contact.active{
  display: block;
  width: 100%;
}

.svg-favela-hover-white {
  width: 50px;
  height: 50px;
  fill: "#ffffff";
}

.svg-favela-hover-white:hover {
  fill: "#fa605e";
}